<template>
  <div :class="topfiexd ? 'header noFixed' : scrollTop ? 'header' : 'header scroll'">
    <img class="logo" src="https://image.ceekee.com/pc/tripwise/logo.png" />
    <div class="headerBox">
      <div class="loginBox">
        <p>
          <span @click="tomanage('/login')">登录</span>
        </p>
        <p>
          <span @click="tomanage('/appointment')">申请体验</span>
        </p>
      </div>
      <ul>
        <li>
          <router-link to="/" exact>首页</router-link>
        </li>
        <li v-for="(item, index) in headMeau" :key="index">
          <router-link :class="item.class_true ? 'on' : ''" :to="item.url">{{ item.title }}</router-link>
          <div class="meauLi">
            <dl v-if="item.meauLi">
              <dd v-for="(val, key) in item.meauLi" :key="key">
                <router-link :to="val.url">{{ val.title }}</router-link>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="sign-btn" @click="handleClickRegisterEnjoy" v-if="!hasSigned && !dialogVisible && isShowSign">
      <img src="https://image.ceekee.com/officialwebsite/sign_float_btn.png" alt="" />
    </div> -->

    <el-dialog
      :visible.sync="dialogVisible"
      width="37vw"
      :show-close="false"
      custom-class="sign-dialog"
      :append-to-body="true"
      :lock-scroll="false"
      @closed="handleClosed"
    >
      <div class="dialog-content">
        <div class="banner">
          <div class="banner-left">
            <img src="https://image.ceekee.com/officialwebsite/dialog_sy.png" />
          </div>
          <div class="banner-right">
            <img src="https://image.ceekee.com/officialwebsite/dialog_card.png" />
          </div>
        </div>
        <div class="dialog-form">
          <el-form
            ref="ruleFormRef"
            label-position="top"
            label-width="5.21vw"
            :lock-scroll="false"
            :model="formLabelAlign"
            style="width: 17.71vw"
            :rules="rules"
          >
            <el-form-item label="联系方式" prop="phone">
              <el-input v-model="formLabelAlign.phone" placeholder="请输入您的号码" />
            </el-form-item>
            <el-form-item label="验证码" prop="smsCode">
              <AliyunCaptcha ref="AliyunCaptcha" @BizResult="getBizResult"></AliyunCaptcha>
              <el-row class="w-full">
                <el-col :span="18">
                  <el-input v-model="formLabelAlign.smsCode" placeholder="请输入验证码" />
                </el-col>
                <el-col :span="6">
                  <el-button
                    v-if="!count"
                    type="text"
                    size="small"
                    link
                    @click="sendSms"
                    :disabled="!formLabelAlign.phone"
                    style="margin-left: 5px"
                  >
                    发送验证码
                  </el-button>
                  <div class="code-count" v-else>{{ count }}s</div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="formLabelAlign.companyName" placeholder="请输入企业名称" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="formLabelAlign.email" placeholder="请输入您的邮箱" />
            </el-form-item>
            <el-form-item class="center-item">
              <el-button type="primary" class="form-btn" @click="submitForm('ruleFormRef')">立即领取 </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <img src="https://image.ceekee.com/officialwebsite/closed.png" class="closed-icon" @click="handleClosed" />
    </el-dialog>
    <DialogNotify :isShow="notifyVisible" @closed="notifyVisible = false"></DialogNotify>
    <ul class="fixedBox">
      <li @mouseover="hoverIndex(0)">
        <img src="https://image.ceekee.com/pc/tripwise/fixed3.png" />
        <div
          class="floatBox contact"
          :class="[{ 'first-child-show': isHover && !isClose }, { 'first-child-close': isClose }]"
        >
          <div class="b-flex b-align-bettwen b-align-centenr">
            <span>联系我们</span>
            <img
              class="guanbi-img"
              @click="closeHover"
              src="https://image.ceekee.com/pc/tripwise/icon_close_darkGrey_20.png"
            />
          </div>
          {{ this.$config.phone_number }}
          <br />180-5110-2396
        </div>
      </li>
      <li @mouseover="hoverIndex(1)">
        <img src="https://image.ceekee.com/pc/tripwise/fixed1.png" />
        <div class="floatBox">
          <img src="https://image.ceekee.com/pc/secondwebsite/erweima_zixun.png" />
          <p>
            在线咨询
            <br />
            <b>微信扫一扫</b>
          </p>
        </div>
      </li>
      <li @mouseover="hoverIndex(3)">
        <router-link :to="this.$config.appointment_url">
          <img src="https://image.ceekee.com/pc/tripwise/fixed4.png" />
        </router-link>
      </li>
    </ul>
    <div class="online-service-box" @click="openOnlineService">
      <img class="online-service-img" src="https://image.ceekee.com/pc/tripwise/img_home_onlineservice.png" alt="" />
      <div class="online-service-text">在线客服</div>
    </div>
  </div>
</template>

<script>
import DialogNotify from "../components/DialogNotify.vue";
import AliyunCaptcha from "../components/AliyunCaptcha.vue";
import YSF from "@neysf/qiyu-web-sdk";

export default {
  name: "Header",
  components: {
    DialogNotify,
    AliyunCaptcha,
  },
  props: ["topfiexd"],
  computed: {
    isShowSign() {
      return this.$store.state.signIsShow;
    },
  },
  data() {
    return {
      notifyVisible: false,
      dialogVisible: false,
      hasSigned: false,
      hasClosed: false,
      scrollTop: true,
      pathname: "12",
      plantId: "1039",
      formLabelAlign: {
        name: "",
        phone: "",
        imgCode: "",
        smsCode: "",
        companyName: "",
        email: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入您的号码", trigger: "blur" },
          {
            pattern: /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        companyName: [{ required: false, message: "请输入企业名称", trigger: "blur" }],
        email: [{ required: false, message: "请输入您的邮箱", trigger: "blur" }],
      },
      headMeau: [
        {
          title: "产品",
          url: "/product",
          class_true: false,
        },
        {
          title: "解决方案",
          url: "/resourceSolution",
          class_true: false,
          meauLi: [
            {
              title: "资源解决方案",
              url: "/resourceSolution",
            },
            {
              title: "数据解决方案",
              url: "/dataSolution",
            },
            {
              title: "金融解决方案",
              url: "/financeSolution",
            },
            {
              title: "跨平台解决方案",
              url: "/crossSolution",
            },
          ],
        },
        {
          title: "客户服务",
          url: "/customerService",
          class_true: false,
        },
        {
          title: "关于我们",
          url: "/aboutUs",
          class_true: false,
          meauLi: [
            {
              title: "公司介绍",
              url: "/aboutUs",
            },
            {
              title: "加入我们",
              url: "/joinUs",
            },
            {
              title: "帮助中心",
              url: "/help",
            },
          ],
        },
      ],
      isHover: true,
      isClose: false,
      timer: null,
      count: 0,
      imgCodeUrl: "",
      validateCode: null,
      captchaVerifyParam: null,
    };
  },
  mounted() {
    this.hasSigned = !!sessionStorage.getItem("hasSigned");
    this.hasClosed = !!sessionStorage.getItem("hasClosed");
    // this.dialogVisible = !this.hasClosed;
    window.addEventListener("scroll", this.menu);
  },
  created() {
    let hover = window.localStorage.getItem("contactHoverNotShow") || 0;
    if (hover == 1) {
      this.isHover = false;
    }
  },
  methods: {
    openOnlineService() {
      YSF.init("38faac50e515bdef64d1ddd9acd9fe30", {
        // templateId: 123, // templateId表示自定义会话邀请的模板标识
        // shuntId: 123, // shuntId表示访客选择多入口分流模版id
        // sessionInvite: false, // sessionInvite表示是否开启会话邀请设置
        hidden: 0, // hidden表示是否隐藏访客端默认入口
      })
        .then((ysf) => {
          ysf("open");
        })
        .catch((error) => {
          console.log("sdk加载失败---", error);
        });
    },
    hoverIndex(index) {
      if (index == 0) {
        if (this.isClose) {
          return;
        }
        this.isHover = true;
        this.isClose = false;
      } else {
        this.isHover = false;
      }
    },
    handleClosed() {
      this.dialogVisible = false;
      this.resetForm("ruleFormRef");
      if (!sessionStorage.getItem("hasClosed")) {
        sessionStorage.setItem("hasClosed", "1");
      }
      clearInterval(this.timer);
      this.timer = null;
      this.count = 0;
    },
    handleClickRegisterEnjoy() {
      this.dialogVisible = true;
      this.captchaVerifyParam = null;
    },
    //发送验证码
    sendSms() {
      if (!this.checkInput()) return;
      this.$refs.AliyunCaptcha && this.$refs.AliyunCaptcha.handleCaptchaButton(this.formLabelAlign.phone);
    },
    getBizResult(res) {
      this.captchaVerifyParam = res.captchaVerifyParam;
      if (res.data) {
        this.getCode();
      }
    },
    checkInput() {
      if (!this.formLabelAlign.phone) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (this.formLabelAlign.phone.length !== 11) {
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      return true;
    },
    //验证码倒计时
    getCode() {
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    submitForm(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          self.confirmSubmit();
        }
      });
    },
    confirmSubmit() {
      if (!this.checkInput()) return;
      if (!this.formLabelAlign.smsCode) {
        this.$message.error("请输入验证码");
        return false;
      }
      this.$api
        .experience({
          PlatId: this.plantId,
          ContactsName: this.formLabelAlign.phone,
          ContactsPhone: this.formLabelAlign.phone,
          EnterpriseName: this.formLabelAlign.companyName,
          Email: this.formLabelAlign.email,
          SMSCode: this.formLabelAlign.smsCode,
          captchaVerifyParam: this.captchaVerifyParam,
          Version: "5.0.3",
        })
        .then((res) => {
          //申请成功
          if (res.Code === "0000") {
            this.dialogVisible = false;
            this.resetForm("ruleFormRef");
            sessionStorage.setItem("hasSigned", "1");
            this.hasSigned = true;
            this.notifyVisible = true;
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch(() => {
          this.$message.error(res.Msg);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    closeHover() {
      window.localStorage.setItem("contactHoverNotShow", 1);
      this.isHover = false;
      this.isClose = true;
      setTimeout(() => {
        this.isClose = false;
      }, 1000);
    },
    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll) {
        this.scrollTop = false;
      } else {
        this.scrollTop = true;
      }
    },
    tomanage(type) {
      if (type == "/login") {
        window.location.href = "https://www.ceekee.com/#/login?source=1039";
      } else if (type == "/register") {
        this.$router.push({ path: "/sign" });
      } else if (type == "/appointment") {
        this.$router.push({ path: "/appointment" });
      }
    },
  },
  watch: {
    $route(to, from) {
      this.pathname = to.name;
    },
    pathname(val) {
      let routerList = this.$router.options.routes;
      let curRouteName = val;
      let parentPath = "";
      routerList.map((item) => {
        if (item.children) {
          item.children.map((i) => {
            if (i.name === curRouteName) {
              parentPath = item.path;
            }
          });
        }
      });
      this.headMeau.map((item) => {
        if (parentPath === item.url) {
          item.class_true = true;
        } else {
          item.class_true = false;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
.header {
  .logo {
    width: 132px;
    position: absolute;
    left: 17%;
    top: 22px;
  }

  width: 100%;
  min-width: 1100px;
  height: 90px;
  position: fixed;
  z-index: 110;
  transition: all 1s;
  top: 0;
  left: 0;

  &.noFixed {
    transition: all 0s;
    background: #040b21;
    z-index: 1;
  }

  &.scroll {
    background: rgba(6, 9, 34, 0.7);
  }

  .headerBox {
    max-width: 1220px;
    width: 90%;
    margin: 0 auto;
    line-height: 35px;

    img {
      float: left;
      margin: 22px 0;
    }

    ul {
      float: right;
      margin: 27px 0;

      li {
        float: left;
        margin-right: 60px;
        text-align: center;
        position: relative;

        &:hover {
          .meauLi {
            display: block;
          }
        }

        &.item a::after {
          content: "";
          width: 40px;
          height: 4px;
          background: #007aff;
          border-radius: 2px;
          position: absolute;
          left: 50%;
          margin-left: -20px;
          bottom: -8px;
        }

        a {
          color: #fff;
          display: block;
          position: relative;
        }

        > a {
          &.router-link-exact-active::after,
          &.on::after {
            content: "";
            width: 40px;
            height: 4px;
            background: #007aff;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            margin-left: -20px;
            bottom: -8px;
          }

          &:hover::after {
            content: "";
            width: 40px;
            height: 4px;
            background: #007aff;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            margin-left: -20px;
            bottom: -8px;
          }
        }

        .meauLi {
          display: none;
          width: 160px;
          position: absolute;
          left: 50%;
          margin-left: -80px;
          padding-top: 15px;
        }

        dl {
          background: #ffffff;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 10px 0;
          font-size: 18px;

          a {
            color: #222222;
            font-size: 14px;

            &:hover {
              color: #007aff;
            }

            &.router-link-exact-active {
              color: #007aff;
            }
          }
        }
      }
    }

    .loginBox {
      float: right;
      margin: 27px 0 0 15px;

      p {
        float: left;
        width: 80px;
        background: #007aff;
        border-radius: 4px;
        text-align: center;
        margin-left: 10px;

        span {
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }

        &:last-child {
          background: none;
          line-height: 33px;
          border: 1px solid #007aff;
        }
      }
    }
  }
}

.sign-btn {
  width: 48px;
  height: 156px;
  position: fixed;
  right: 20px;
  top: 550px;

  img {
    width: 100%;
    height: auto;
  }
}

/deep/ .sign-dialog {
  border-radius: 16px;

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }

  .dialog-content {
    .banner {
      background: linear-gradient(135deg, #ff9359 0%, #ff271e 100%);
      display: flex;
      position: relative;
      border-radius: 16px 16px 0 0;
      height: 10.36vw;

      .banner-left {
        padding: 1.45vw 0 1.45vw 1.45vw;
        //margin-right: 1.77vw;
        width: 28.13vw;

        img {
          width: 100%;
        }
      }

      .banner-right {
        width: 13.125vw;
        position: relative;

        img {
          width: 100%;
          top: -1.82vw;
          right: -2.08vw;
          position: absolute;
        }
      }
    }

    .dialog-form {
      background-color: #ffffff;
      padding: 1.01vw 0 1.25vw 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 0 0 16px 16px;

      .el-form {
        .el-form-item {
          margin-bottom: 1.25vw;
        }

        .el-form-item__label {
          line-height: unset;
          margin-bottom: 0.52vw;
          padding: 0;
        }

        .el-form-item__content {
          line-height: 32px;

          .el-input {
            .el-input__inner {
              height: 32px !important;
              line-height: 32px !important;
            }
          }
        }
      }
      .w-full {
        width: 100%;
      }
      .h-full {
        height: 100%;
      }
      .img-code {
        font-size: 14px;
        line-height: 32px;
        color: #0b7afe;
        cursor: pointer;
        width: 3.74vw;
        text-align: center;
        margin-left: 5px;
      }
      .code-count {
        text-align: center;
        color: #606266;
        font-size: 0.84vw;
        height: 32px;
        line-height: 32px;
      }

      .center-item {
        margin-top: 1.875vw;
        margin-bottom: 0 !important;

        .form-btn {
          color: #ffffff;
          background-color: #ff5133;
          height: 2.29vw;
          border: none;
          display: flex;
          align-items: center;
        }

        .el-form-item__content {
          display: flex !important;
          justify-content: center;
        }
      }
    }
  }

  .closed-icon {
    width: 1.67vw;
    height: 1.67vw;
    border-radius: 50%;
    position: absolute;
    top: -2.08vw;
    right: -2.08vw;
    background-color: #ffffff;
  }
}
.online-service-box {
  width: 50px;
  background: #007aff;
  border-radius: 27.5px;
  position: fixed;
  right: 20px;
  top: 380px;
  padding: 10px 0;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  .online-service-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  .online-service-text {
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    margin-top: 10px;
    padding: 0 10px;
  }
}

.fixedBox {
  width: 50px;
  background: #007aff;
  border-radius: 7px;
  position: fixed;
  right: 20px;
  top: 180px;

  li {
    padding: 18px 0 0;
    cursor: pointer;
    position: relative;

    &:hover {
      background: rgba(31, 12, 12, 0.1);

      .floatBox {
        left: -260px;
      }
    }

    img {
      width: 25px;
      display: block;
      margin: 0 auto;
      padding-bottom: 18px;
      border-bottom: 1px solid rgba(241, 241, 241, 0.3);
    }

    &:last-child {
      a > img {
        border-bottom: none;
      }
      img {
        border-bottom: none;
      }
    }

    .floatBox {
      position: absolute;
      transition: all 1s;
      width: 260px;
      border-radius: 4px;
      left: 260px;
      top: 0;
      background: #fff;
      box-shadow: 0px 0px 16px rgba(229, 229, 229, 0.5);
      color: #000;
      font-size: 16px;

      img {
        width: 100px;
        float: left;
        border-radius: 7px;
        padding-bottom: 0;
      }

      p {
        width: 120px;
        text-align: center;
        float: left;
        margin: 25px 0 0 10px;
        font-size: 17px;
        line-height: 25px;
        color: #222222;
      }

      &.contact {
        top: 50%;
        padding: 10px 20px;
        margin-top: -53px;
        width: 210px;
      }
    }

    .first-child-show {
      left: -260px !important;
    }

    .first-child-close {
      left: 260px !important;
    }
  }
}

@media screen and (max-width: 1300px) {
  .header {
    .headerBox ul li {
      margin-right: 40px !important;
    }
  }
}

.guanbi-img {
  height: 16px !important;
  width: 16px !important;
  float: right !important;
  margin-top: 5px !important;
}
</style>
